import { ThemeProvider as EmotionThemeProvider } from '@emotion/react'
import React, { memo } from 'react'

import { MediaContextProvider } from './media'

export type Theme = typeof theme

export const theme = {
  colors: {
    variants: {
      neutralLight4: '#FFFFFF',
      neutralLight3: '#F2F2F2',
      neutralDark4: '#696767',
      neutralDark3: '#333333',
      neutralDark2: '#1F1F1F',
      neutralDark1: '#000000',
      primaryDark: '#4D7672',
      primaryLight: '#5A8682',
      secondaryDark: '#AC917D',
      secondaryLight: '#CCB6A7',
      dangerLight: '#CC2121',
    },
  },
  fontFamily: {
    heading: "'Allerta Stencil', sans-serif",
    paragraph: "'Inter', sans-serif",
  },
}

export const ThemeProvider = memo(function ThemeProvider({ children }) {
  return (
    <EmotionThemeProvider theme={theme}>
      <MediaContextProvider>{children}</MediaContextProvider>
    </EmotionThemeProvider>
  )
})
